<template>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }" style="font-size: 20px;">智能采集</el-breadcrumb-item>
</el-breadcrumb>

  <!-- 搜索区域 -->
  <div class="search">
    <span>文章发布/编辑时间段：</span>
    <div class="block">
      <el-date-picker
          v-model="modified_time_where"
          type="datetimerange"
          @change="loadTable()"
          :shortcuts="shortcuts"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
      />
    </div>

    <span>采集时间段：</span>
    <div class="block">
      <el-date-picker
          v-model="create_time_where"
          type="datetimerange"
          :shortcuts="shortcuts"
          @change="loadTable()"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
      />
    </div>

    <span>域名筛选：</span>
    <el-select
        v-model="search_query_domain"
        :loading="query_loading"
        placeholder="筛选采集域名"
        size="large"
        @change="loadTable()"
        clearable
        style="width: 240px"
    >
      <el-option
          v-for="item in domainOptions"
          :label="item.label"
          :value="item.value"
      />
    </el-select>

    <span>关键字搜索：</span>

    <el-input
      v-model="search"
      style="width: 340px"
      placeholder="输入网址标题或关键字查询"
      @keydown="isEnter"
      clearable
    />
    <el-button size="mini" type="primary" @click="loadTable">查询</el-button>

    <el-button  color="#626aef" size="mini" type="primary" style="float: right" @click="openConfigDrawer()">
      智能采集配置面板
    </el-button>
    <ai-query-config ref="queryConfigChild"></ai-query-config>

  </div>
  <!-- 表格区域 -->
  <el-table
    v-loading="loading"
    size="mini"
    :data="tableData"
    style="width: 99%"
  >
    <el-table-column prop="id" label="编号" width="100" />
    <el-table-column prop="query_domain" label="域名(点击域名跳转具体采集地址)" width="200" >
      <template v-slot="scope">
        <el-link icon="el-icon-link" :href="scope.row.query_url" target="_blank">{{scope.row.query_domain}}</el-link>
      </template>
    </el-table-column>

    <el-table-column prop="query_title" label="采集标题" width="300" >
      <template v-slot="scope">
        <el-popover
            placement="top-start"
            :width="300"
            trigger="hover"
            :fallback-placements="['bottom', 'top', 'right', 'left']"
        >

          <template #reference>
            <el-button  class="show_button" type="text" text slot="reference">{{scope.row.query_title}}</el-button>
          </template>
          <template #default>
            <p v-html="scope.row.query_title"></p>
          </template>
        </el-popover>
      </template>
    </el-table-column>
    <!-- <el-table-column prop="query_url" label="网址" width="600" /> -->

    <el-table-column prop="change_content" label="转换后内容"  >
      <template v-slot="scope">
        <el-popover
            placement="top-start"
            :title="scope.row.query_title"
            :width="700"
            trigger="hover"
            :fallback-placements="['bottom', 'top', 'right', 'left']"
            >

          <template #reference>
            <el-button class="show_button" type="text" slot="reference">【移入查看】{{scope.row.change_content}}</el-button>
          </template>
          <template #default>
            <p v-html="scope.row.change_content"></p>
          </template>
        </el-popover>
      </template>
    </el-table-column>

    <el-table-column prop="content" label="转换前原文" width="600" >
      <template v-slot="scope">
        <el-popover
            placement="top-start"
            :title="scope.row.query_title"
            :width="1000"
            trigger="hover"
            :fallback-placements="['bottom', 'top', 'right', 'left']"
            >

          <template #reference>
            <el-button class="show_button" type="text" slot="reference">【移入查看】{{scope.row.content}}</el-button>
          </template>
          <template #default>
            <p v-html="scope.row.content"></p>
          </template>
        </el-popover>
      </template>
    </el-table-column>

    <el-table-column prop="modified_time" label="网页编辑/发布时间" width="180" />

    <el-table-column prop="create_time" label="采集时间" width="180" />

  </el-table>
  <!-- 分页 -->
  <el-button type="text" style="display: inline-block;">总条数：{{total}}</el-button>

  <div class="pagination">
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="step"
      :total="total"
      v-model:current-page="page"
    >
    </el-pagination>
  </div>

</template>

<script>
import { reactive, toRefs, watch, onMounted,ref } from "vue";
import { $confirm, $msg_s, $msg_e } from "@/utils/msg";
// 导入角色的API方法
import { aiQueryList,aiQueryDomainList } from "@/api/webList";
import AiQueryConfig from '@/views/aiQuery/config.vue';

// import { useRouter } from "vue-router";
export default {
  name: "list",
  components: {
    AiQueryConfig
  },
  methods:{
    //打开配置项弹窗
     openConfigDrawer(){
      this.$refs.queryConfigChild.drawer = true;
    }
  },
  setup() {
    //定义数据
    let data = reactive({
      //表格数据
      tableData: [],
      //总数量
      total: 0,
      loading: true,
      query_loading:false,
      queryConfigChild:null,
      domainOptions:[
      ],
      // formLabelWidth: "100px",
      msg: "",
      tags: [],
      //当前页码
      page: 1,
      //每页数量
      step: 15,
      //搜索内容
      search: "",
      shortcuts:[
        {
          text: '今日',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime())
            return [start, end]
          },
        },
        {
          text: '昨日',
          value: () => {
            const end = new Date()
            const start = new Date()
            end.setTime(start.getTime() - 3600 * 1000 * 24)
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            return [start, end]
          },
        },
        {
          text: '一周内',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '一个月内',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '三个月内',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
      //采集时间条件
      create_time_where:[],
      modified_time_where:[],
      search_query_domain:'',  //筛选采集域名
    });
    // 监听当前页码是否发生变化
    watch(
        () => data.page,
        () => {
          //重新加载表格数据
          loadTable();
        }
    );
    // 在setup函数内部
    // const router = useRouter();
    onMounted(() => {
      //执行加载表格数据的方法
      const today = new Date();
      today.setTime(today.getTime() - 3600 * 1000 * 24)

      const todayStart = () => {
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const todayEnd = () => {
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      data.modified_time_where=[
        todayStart(),
        todayEnd()
      ];
      loadTable();
      loadDomainOptions();
    });

    //加载表格数据的方法
    let loadTable = async () => {
      data.loading = true;
      //查询条件
      let params = {
        page: data.page,
        step: data.step,
        search: data.search,
        create_time_where:data.create_time_where?data.create_time_where:[],
        modified_time_where:data.modified_time_where?data.modified_time_where:[],
        search_query_domain:data.search_query_domain,
      };
      //获取查询结果
      let res = await aiQueryList(params);
      data.total = res.data.count;
      data.tableData = res.data.data;
      data.loading = false;
    };
    //加载域名筛选列表
    let loadDomainOptions = async () => {
        data.query_loading=true;
        let res = await aiQueryDomainList();
        data.domainOptions = res.data;
        data.query_loading=false;
    };

    //输入网址键盘事件
    let isEnter = (event) => {
      if (event.keyCode == 13) {
        //执行加载表格数据的方法
        loadTable();
      }
    };
    return {
      ...toRefs(data),
      loadTable,
      isEnter
    };
  },
};
</script>
<style scoped lang="scss">
.search{
	margin-top: 1%;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}

.show_button{
  width: 90%;
  text-overflow:ellipsis;
  overflow: hidden; /* 确保超出容器的文本被裁剪 */
  white-space: nowrap; /* 确保文本在一行内显示 */
}
.el-button--text{
  border-color: transparent;
  color: var(--el-text-color-regular);
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
